import MarketEvent from '@root/market-manager.admin.joinroot.com/src/components/market-event';
import PropTypes from '@root/vendor/prop-types';
import dayjs from '@root/vendor/dayjs';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function MarketEventLog({ logs }) {
  return (
    <div>
      {logs.map(({
        market, description, whodunnit, createdAt,
      }, idx) => (
        <div
          css={styles.log}
          key={idx}
        >
          <div css={styles.timeContainer}>
            <div css={idx === 0 ? styles.noLines : styles.lines} />
            <span css={styles.time}>
              {`${dayjs(createdAt).format('M/D/YY')} at ${dayjs(createdAt).format('h:mm a')}`}
            </span>
            <div css={idx === logs.length - 1 ? styles.noLines : styles.lines} />
          </div>
          <MarketEvent
            description={description}
            market={market}
            whodunnit={whodunnit}
          />
        </div>
      ))}
    </div>
  );
}

MarketEventLog.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    whodunnit: PropTypes.string,
    market: PropTypes.string,
    time: PropTypes.string,
  })),
};

const styles = StyleSheet.create({
  log: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 200,
    width: 800,
  },
  lines: {
    background: '#000',
    width: '1px',
    minHeight: '10px',
    flexGrow: 1,
  },
  noLines: {
    background: '#FFF',
    width: '1px',
    flexGrow: 1,
  },
  time: {
    ...Theme.paragraph1(),
    color: Colors.gray60(),
    flexGrow: 0,
    padding: '12px',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'center',
    width: '200px',
  },
});
