import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useContext, useEffect, useState } from '@root/vendor/react';
import getMarketStatusesConfiguration from '@root/market-manager.admin.joinroot.com/src/api/get-market-statuses-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useOktaAuth } from '@root/vendor/@okta/okta-react';

const MarketStatusesContext = React.createContext();

export default function MarketStatusesContextProvider({ children }) {
  const { authState } = useOktaAuth();

  const isAuthenticated = authState?.isAuthenticated;

  const [fetchMarketStatuses, areMarketStatusesLoading] = useImperativeNetworkRequest(getMarketStatusesConfiguration);

  const [marketStatuses, setMarketStatuses] = useState([]);

  const getMarketStatuses = useCallback(async () => {
    if (isAuthenticated) {
      const result = await fetchMarketStatuses();

      if (result?.isSuccess()) {
        setMarketStatuses(result.data.marketStatuses);
      }
    }
  }, [fetchMarketStatuses, isAuthenticated]);

  useEffect(() => {
    if (marketStatuses.length === 0) {
      getMarketStatuses();
    }
  }, [getMarketStatuses, marketStatuses]);

  return (
    <MarketStatusesContext.Provider
      value={{
        areMarketStatusesLoading,
        marketStatuses,
        reloadMarketStatuses: getMarketStatuses,
      }}
    >
      {children}
    </MarketStatusesContext.Provider>
  );
}

MarketStatusesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useMarketStatusesContext() {
  const {
    areMarketStatusesLoading, marketStatuses, reloadMarketStatuses,
  } = useContext(MarketStatusesContext);

  return {
    areMarketStatusesLoading,
    marketStatuses,
    reloadMarketStatuses,
  };
}
