import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import styled from '@root/vendor/@emotion/styled';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function MultiSelectOption({
  onClick, selected, value,
}) {
  return (
    <div css={styles.checkboxWrapper}>
      <StyledCheckbox>
        <label className={'checkbox__content'}>
          <input
            checked={selected}
            data-testid={value}
            onClick={onClick}
            readOnly={true}
            type={'checkbox'}
          />
          <span className={'checkbox__indicator'} />
        </label>
      </StyledCheckbox>
    </div>
  );
}

const StyledCheckbox = styled.div(() => {
  return {
    '.checkbox__content': {
      marginBottom: 0,
      userSelect: 'none',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      border: '4px solid transparent',
    },

    '.checkbox__content input': {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
    },

    '.checkbox__indicator': {
      height: 15,
      width: 15,
      backgroundColor: Colors.nearWhite(),
      outline: `1px ${Colors.gray30()} solid`,
      transition: 'all ease-in .2s',
      '&:after': {
        content: '""',
        position: 'absolute',
        color: Colors.white(),
        display: 'none',
      },
    },

    '.checkbox__content:hover input ~ .checkbox__indicator': {
      backgroundColor: Colors.gray40(),
    },

    '.checkbox__content input:checked ~ .checkbox__indicator': {
      backgroundColor: Colors.rootOrange(),
    },

    '.checkbox__content input:checked ~ .checkbox__indicator:after': {
      display: 'block',
    },

    '.checkbox__content .checkbox__indicator:after': {
      left: 6,
      top: 3,
      width: 4,
      height: 8,
      border: `solid ${Colors.white()}`,
      borderWidth: '0px 2px 2px 0px',
      transform: 'rotate(45deg)',
    },

  };
});

const styles = StyleSheet.create({
  checkboxWrapper: {
    height: 21,
    width: 21,
    outline: `1px ${Colors.gray30()} solid`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

MultiSelectOption.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

