import ArrowLeft from '@root/internal-dashboards-core/src/assets/arrow-left';
import ArrowRight from '@root/internal-dashboards-core/src/assets/arrow-right';
import AutoSizer from 'react-virtualized-auto-sizer';
import MultiSelectOption from '@root/internal-dashboards-core/src/components/multi-select-option';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useMemo } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import chevronDown from '@root/core/src/assets/chevron-down.svg';
import chevronUp from '@root/core/src/assets/chevron-up.svg';
import searchIcon from '@root/core/src/assets/search-black.svg';
import { CSVLink } from 'react-csv';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { FixedSizeList } from 'react-window';
import { useFilters, useFlexLayout, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

export const FILTER_TYPES = {
  INCLUDES_SOME: 'includesSome',
  TEXT: 'text',
};

function mapInput(columns, filters = []) {
  let columnsInput = columns;
  if (filters !== []) {
    columnsInput = columns.map((c) => {
      const columnFilter = filters.find((f) => f.id === c.accessor);
      if (columnFilter) {
        return {
          ...c,
          filter: columnFilter['type'],
        };
      }
      return c;
    });
  }

  const filtersInput = filters.map((f) => {
    return {
      id: f.id,
      value: f.value,
    };
  });

  return {
    columnsInput,
    filtersInput,
  };
}

export default function Table({
  clickable = false,
  columns,
  csvExportable = false,
  csvNamePrefix = 'root_table_export',
  data,
  filters,
  initialState = {},
  searchable = false,
  onRowSelect = () => { },
  onRowClick = () => { },
  selectable = false,
  styleOverrides = {},
  title,
  useVirtualRows = false,
  isPaginated = false,
}) {
  const { columnsInput, filtersInput } = useMemo(() => mapInput(columns, filters), [columns, filters]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setAllFilters,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    page,
    state: {
      selectedRowIds, pageIndex, pageSize,
    },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable({
    autoResetFilters: false,
    columns: columnsInput,
    data: useMemo(() => data, [data]),
    disableSortRemove: true,
    initialState,
  },
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  (hooks) => {
    // eslint-disable-next-line no-shadow
    selectable && hooks.visibleColumns.push((columns) => {
      return [
        {
          width: 40,
          id: 'selection',
          groupByBoundary: true,
          Header: (item) => {
            return (
              <div
                css={styles.checkboxCell}
              >
                <MultiSelectOption
                  onClick={(e) => {
                    item.getToggleAllRowsSelectedProps().onChange(e);
                  }}
                  selected={item.isAllRowsSelected}
                  value={'select-all-rows'}
                />
              </div>
            );
          },
          Cell: (item) => {
            return (
              <MultiSelectOption
                onClick={() => {
                  item.row.toggleRowSelected();
                }}
                selected={item.row.isSelected}
                value={'select-row'}
              />
            );
          },
        },
        ...columns,
      ];
    });
  }
  );

  const onSearch = useCallback((e) => {
    setGlobalFilter(e.target.value);
  }, [setGlobalFilter]);

  useEffect(() => {
    onRowSelect(selectedFlatRows.map((r) => r.values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRowSelect, selectedRowIds]);

  useEffect(() => {
    if (selectable && Object.keys(selectedRowIds).length > 0) {
      toggleAllRowsSelected(false);
    }
    setAllFilters(filtersInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectable, setAllFilters, toggleAllRowsSelected, filtersInput]);

  const paginationInfo = () => {
    if (!data.length) {
      return '0 of 0';
    }

    const numberOfRows = data.length;
    const firstRowOnPage = pageIndex * pageSize + 1;
    const lastRowOnPage = Math.min(numberOfRows, pageIndex * pageSize + pageSize);

    return `${firstRowOnPage.toLocaleString()}-${lastRowOnPage.toLocaleString()} of ${numberOfRows.toLocaleString()}`;
  };

  const calculateRows = () => isPaginated ? page : rows;

  const RenderRow = React.useCallback(
    (row, style) => {
      prepareRow(row);
      return (
        <div
          key={row}
          {...row.getRowProps({
            style,
          })}
          className={row.original.active ? 'active' : ''}
          css={[styles.row, styleOverrides.row]}
        >
          {row.cells.map((cell) => {
            return (
              <div
                css={[cell.column.id === 'selection' ? styles.checkboxCell : styles.cell, (clickable || selectable) && styles.clickable, styleOverrides.cell]}
                key={cell}
                {...cell.getCellProps()}
                onClick={() => cell.column.id !== 'selection' && (clickable ? onRowClick(row.original) : row.toggleRowSelected())}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    },
    [clickable, onRowClick, prepareRow, styleOverrides.cell, styleOverrides.row, selectable]
  );

  const RenderVirtualRow = useCallback(({ index, style }) => {
    const row = rows[index];
    return RenderRow(row, style);
  }, [RenderRow, rows]);

  return (
    <div
      {...getTableProps()}
      css={[styles.table, styleOverrides.table]}
    >

      <div
        css={[styles.tableHeaderGroup, styleOverrides.tableHeaderGroup]}
      >
        {
          searchable && (
            <div
              css={[styles.searchHeader, styleOverrides.searchHeader]}
            >
              <input
                css={[styles.searchHeaderInput, styleOverrides.searchHeaderInput]}
                onChange={onSearch}
                placeholder={'Search...'}
                type={'text'}
              />
              <img
                alt={'search'}
                css={[styles.searchIcon, styleOverrides.searchIcon]}
                src={searchIcon}
              />
            </div>
          )
        }
        {
          title && (
            <div css={[styles.titleContent, styleOverrides.titleContent]}>
              {title}
            </div>
          )
        }
        <div css={styles.rightHeaderContent}>
          {
            csvExportable && !!data.length && (
              <div
                css={styles.csvExportContainer}
              >
                <CSVLink
                  css={styles.csvExportLink}
                  data={data}
                  filename={(csvNamePrefix + '_' + new Date().toLocaleString())}
                  headers={null}
                >
                  {'Export to CSV'}
                </CSVLink>
              </div>
            )
          }
          {
            isPaginated && (
              <div
                css={styles.paginationContainer}
                data-testid={'pagination'}
              >
                <div css={styles.paginationInfo}>
                  {paginationInfo()}
                </div>
                <div css={styles.paginationButtons}>
                  <div
                    css={canPreviousPage ? styles.paginationButton : styles.paginationButtonInactive}
                    onClick={() => previousPage()}
                  >
                    <ArrowLeft
                      css={canPreviousPage ? styles.paginationButton : styles.paginationButtonInactive}
                      strokeColor={canPreviousPage ? Colors.gray50() : Colors.gray40()}
                    />
                  </div>
                  <div
                    css={canNextPage ? styles.paginationButton : styles.paginationButtonInactive}
                    onClick={() => nextPage()}
                  >
                    <ArrowRight strokeColor={canNextPage ? Colors.gray50() : Colors.gray40()} />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div css={[styles.tableContentsContainer, styleOverrides.tableContentsContainer]}>
        <div css={styles.headerSpacingContainer}>
          <div
            css={[styles.header, styleOverrides.header]}
            role={'headergroup'}
          >
            {headerGroups.map((headerGroup) => (
              <div
                css={styleOverrides.headerRow}
                key={headerGroup}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <div
                    css={[styles.headerItem, selectable ? styles.selectableHeaderItem : null, styleOverrides.headerItem]}
                    key={column}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div css={[styles.headerItemContainer, column.isSorted ? styles.sorted : null, column.id === 'selection' ? styles.headerSelection : null, styleOverrides.headerItemContainer]}>
                      <div>
                        {column.render('Header')}
                      </div>
                      <div>
                        {column.isSorted ? (
                          <img
                            css={styles.icon}
                            src={column.isSortedDesc ? chevronDown : chevronUp}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div
          {...getTableBodyProps()}
          css={[styles.tableRows, styleOverrides.tableRows]}
        >
          {
            useVirtualRows ? (
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    className={'List'}
                    height={height}
                    itemCount={rows.length}
                    itemSize={35}
                    width={width}
                  >
                    {RenderVirtualRow}
                  </FixedSizeList>
                )}
              </AutoSizer>
            ) :
              calculateRows(rows, page, isPaginated).map((row) => RenderRow(row))
          }
        </div>
      </div>
    </div>
  );
}

Table.propTypes = {
  clickable: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.func,
      ]),
      accessor: PropTypes.string.isRequired,
      sortType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
    })
  ).isRequired,
  csvExportable: PropTypes.bool,
  csvNamePrefix: PropTypes.string,
  data: PropTypes.array.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  })),
  initialState: PropTypes.object,
  isPaginated: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowSelect: PropTypes.func,
  resetSelect: PropTypes.bool,
  searchable: PropTypes.bool,
  selectable: PropTypes.bool,
  styleOverrides: PropTypes.shape({
    cell: PropTypes.object,
    headerItem: PropTypes.object,
    headerItemContainer: PropTypes.object,
    headerRow: PropTypes.object,
    row: PropTypes.object,
    rowItem: PropTypes.object,
    rowItemContainer: PropTypes.object,
    searchHeader: PropTypes.object,
    searchHeaderIcon: PropTypes.object,
    searchHeaderInput: PropTypes.object,
    table: PropTypes.object,
    tableHeaderGroup: PropTypes.object,
    tableContentsContainer: PropTypes.object,
    tableRows: PropTypes.object,
    titleContent: PropTypes.object,
  }),
  styleTest: PropTypes.object,
  title: PropTypes.string,
  useVirtualRows: PropTypes.bool,
};

const styles = StyleSheet.create({
  clickable: {
    cursor: 'pointer',
  },
  table: {
    borderSpacing: 0,
    height: '100%',
  },
  tableHeaderGroup: {
    display: 'flex',
    flexDirection: 'inline',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: Colors.white(),
    ...Responsive.lessThanSm({
      fontSize: 12,
      width: '100%',
    }),
  },
  searchHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchHeaderInput: {
    border: `1px solid ${Colors.gray10()}`,
    fontSize: 14,
    width: 330,
    height: 26,
    outline: 'none',
    padding: 8,
    '::placeholder': {
      fontStyle: 'italic',
    },
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  searchIcon: {
    color: Colors.nearBlack(),
    position: 'relative',
    height: 14,
    width: 14,
    left: -20,
    top: 7,
  },
  csvExportContainer: {
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  csvExportLink: {
    fontSize: 14,
    fontWeight: 'normal',
    color: Colors.darkBlue(),
  },
  paginationContainer: {
    display: 'flex',
    color: Colors.gray50(),
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 200,
    ...Responsive.lessThanSm({
      width: 'auto',
    }),
  },
  paginationInfo: {
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  paginationButtons: {
    display: 'flex',
  },
  paginationButton: {
    cursor: 'pointer',
    marginLeft: 30,
  },
  paginationButtonInactive: {
    marginLeft: 30,
  },
  headerSpacingContainer: {
    backgroundColor: Colors.nearWhite(),
    borderRight: `1px solid ${Colors.gray10()}`,
  },
  header: {
    backgroundColor: Colors.nearWhite(),
    border: `1px solid ${Colors.gray10()}`,
    borderRight: '4px solid transparent',
  },
  headerItemContainer: {
    color: Colors.gray50(),
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 'normal',
    padding: 8,
    paddingLeft: 30,
  },
  headerSelection: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 0',
  },
  headerItem: {
    borderRight: `1px solid ${Colors.gray20()}`,
    ':last-child': {
      borderRight: 0,
    },
  },
  selectableHeaderItem: {
    ':first-of-type': {
      borderRight: 0,
    },
  },
  tableContentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tableRows: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  sorted: {
    backgroundColor: Colors.gray10(),
    fontWeight: 'bold',
  },
  row: {
    borderBottom: `1px solid ${Colors.gray10()}`,
    backgroundColor: Colors.white(),
    width: 'calc(100% + 16px)',
    borderRight: '4px solid transparent',
    '&:hover, &.active': {
      backgroundColor: '#EDF2F7',
      borderRight: '4px solid #83A6C1',
    },
  },
  cell: {
    color: Colors.gray50(),
    fontSize: 14,
    padding: 8,
    paddingLeft: 30,
    ...Responsive.lessThanSm({
      fontSize: 12,
      paddingLeft: 5,
    }),
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
  },
  icon: {
    userSelect: 'none',
  },
});
