import PropTypes from '@root/vendor/prop-types';

const ArrowRight = ({ strokeColor }) => (
  <svg
    data-testid={'right-arrow-icon'}
    fill={'none'}
    height={'13'}
    viewBox={'0 0 8 13'}
    width={'8'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M1.03516 0.902344L6.53516 6.40234L1.03516 11.9023'}
      stroke={strokeColor}
      strokeWidth={'2'}
    />
  </svg>
);

ArrowRight.propTypes = {
  strokeColor: PropTypes.string,
};

export default ArrowRight;

