export default class Triage {
  static TYPE = {
    UNDERPERFORMING_ATTRIBUTED: 'UNDERPERFORMING_ATTRIBUTED_MARKETS',
    UNDERPERFORMING_UNATTRIBUTED: 'UNDERPERFORMING_UNATTRIBUTED_MARKETS',
    REINSTATEMENT_WITH_LAPSE_ENABLED: 'REINSTATEMENT_WITH_LAPSE_ENABLED_MARKETS',
    FEE_WAIVERS_ENABLED: 'FEE_WAIVERS_ENABLED_MARKETS',
  };

  static TYPE_TO_TEXT = {
    [Triage.TYPE.UNDERPERFORMING_ATTRIBUTED]: 'underperforming attributed',
    [Triage.TYPE.UNDERPERFORMING_UNATTRIBUTED]: 'underperforming unattributed',
    [Triage.TYPE.REINSTATEMENT_WITH_LAPSE_ENABLED]: 'reinstatement with lapse enabled',
    [Triage.TYPE.FEE_WAIVERS_ENABLED]: 'fee waivers enabled',
  }

  static TYPE_TO_NAME = {
    [Triage.TYPE.UNDERPERFORMING_ATTRIBUTED]: 'attributed',
    [Triage.TYPE.UNDERPERFORMING_UNATTRIBUTED]: 'unattributed',
    [Triage.TYPE.REINSTATEMENT_WITH_LAPSE_ENABLED]: 'enabled',
    [Triage.TYPE.FEE_WAIVERS_ENABLED]: 'enabled',
  }

  static TYPE_TO_MESSAGE = {
    [Triage.TYPE.UNDERPERFORMING_ATTRIBUTED]: `triage new ${this.TYPE_TO_NAME[this.TYPE.UNDERPERFORMING_ATTRIBUTED]} business in this market to Classic Only`,
    [Triage.TYPE.UNDERPERFORMING_UNATTRIBUTED]: `triage new ${this.TYPE_TO_NAME[this.TYPE.UNDERPERFORMING_UNATTRIBUTED]} business in this market to Classic Only`,
    [Triage.TYPE.REINSTATEMENT_WITH_LAPSE_ENABLED]: 'cause all policies in this market to have a lapse in coverage when they reinstate',
    [Triage.TYPE.FEE_WAIVERS_ENABLED]: 'allow policyholders in this market to request fee waivers',
  }
}
