import MarketManagerAdminApiNetworkRequestConfiguration from '@root/market-manager.admin.joinroot.com/src/api/market-manager-admin-api-network-request-configuration';

export default function setEquityBillingConfigurationsConfiguration({ segments, market }) {
  return new MarketManagerAdminApiNetworkRequestConfiguration({
    endpoint: 'equity_billing_configuration/set_configuration',
    bodyParameters: {
      segments,
      market,
    },
    successCodes: [200, 201, 204],
    errorCodes: [422],
    method: MarketManagerAdminApiNetworkRequestConfiguration.Methods.POST,
  });
}
