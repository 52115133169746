import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const NAV_BUTTONS = {
  MAP: 'map',
  TABLE: 'table',
};

const NAV_BUTTONS_TO_TEXT = {
  [NAV_BUTTONS.TABLE]: 'Overview',
  [NAV_BUTTONS.MAP]: 'Map View',
};

export default function NavControls({
  selection,
  setSelection,
}) {
  const handleSelectionChange = (event) => {
    setSelection(event.target.id);
    event.target.blur();
  };

  return (
    <div
      data-testid={'selection-controls'}
    >
      {Object.entries(NAV_BUTTONS_TO_TEXT).map(([selectionName, buttonText], i) => {
        const isActive = selection === selectionName;

        return (
          <button
            css={[styles.splitButton, isActive && styles.buttonIsActive]}
            data-testid={`${selectionName}-${isActive}`}
            id={selectionName}
            key={i}
            onClick={(e) => handleSelectionChange(e)}
          >
            {buttonText}
          </button>
        );
      })}
    </div>
  );
}

NavControls.propTypes = {
  selection: PropTypes.string,
  setSelection: PropTypes.func,
};

export { NAV_BUTTONS, NAV_BUTTONS_TO_TEXT };

const styles = StyleSheet.create({
  splitButton: {
    position: 'relative',
    height: '100%',
    padding: '8px 18px',
    backgroundColor: Colors.white(),
    border: 'none',
    color: Colors.rootOrange(),
    fontSize: 16,
  },
  buttonIsActive: {
    background: 'rgba(255, 213, 196, 0.5)',
    color: Colors.rootOrange(),
  },
});
