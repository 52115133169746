import PropTypes from '@root/vendor/prop-types';
import { Colors } from '@root/core/src/utils/styles';

export default function XIcon({ strokeColor = Colors.black() }) {
  return (
    <svg
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <title>Close Icon</title>
      <path
        d={'M21.5 3L3.5 21'}
        stroke={strokeColor}
        strokeWidth={'2'}
      />
      <path
        d={'M21.5 21L3.5 3'}
        stroke={strokeColor}
        strokeWidth={'2'}
      />
    </svg>
  );
}

XIcon.propTypes = {
  strokeColor: PropTypes.string,
};
