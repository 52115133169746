import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default class MarketManagerApiNetworkRequestConfiguration extends NetworkRequestConfiguration {
  static Methods = NetworkRequestConfiguration.Methods

  constructor(params) {
    super({
      endpointBase: 'market_manager_dashboard',
      ...params,
    });
  }
}
