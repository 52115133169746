import CaretLoader from '@root/core/src/components/caret-loader';
import MarketEventLogs from '@root/market-manager.admin.joinroot.com/src/components/market-event-logs';
import MarketStatusMap from '@root/market-manager.admin.joinroot.com/src/components/map-section/market-status-map';
import MarketTable from '@root/market-manager.admin.joinroot.com/src/components/market-table';
import MetricControls, { METRICS } from '@root/market-manager.admin.joinroot.com/src/components/map-section/metric-controls';
import NavControls from '@root/market-manager.admin.joinroot.com/src/components/nav-controls';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import TriageOptionsModal, { TRIAGE_YES } from '@root/market-manager.admin.joinroot.com/src/components/triage-options-modal';
import disableConfigurationVariableConfiguration from '@root/market-manager.admin.joinroot.com/src/api/disable-configuration-variable-configuration';
import enableConfigurationVariableConfiguration from '@root/market-manager.admin.joinroot.com/src/api/enable-configuration-variable-configuration';
import getMarketEventLogsConfiguration from '@root/market-manager.admin.joinroot.com/src/api/get-market-event-logs-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { NAV_BUTTONS } from '@root/market-manager.admin.joinroot.com/src/components/nav-controls';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useAccess } from '@root/market-manager.admin.joinroot.com/src/services/access-service';
import { useEquityBillingConfigurationsContext } from '@root/market-manager.admin.joinroot.com/src/hooks/use-equity-billing-configurations-context';
import { useHistory } from '@root/core/src/components/route';
import { useMarketStatusesContext } from '@root/market-manager.admin.joinroot.com/src/hooks/use-market-statuses-context';

export default function Landing() {
  const history = useHistory();
  const [selectedTriageMarket, setSelectedTriageMarket] = useState();
  const [selectedTriageType, setSelectedTriageType] = useState();
  const [selectedTriageValue, setSelectedTriageValue] = useState();
  const [isTriageModalVisible, setIsTriageModalVisible] = useState(false);
  const [viewType, setViewType] = useState(NAV_BUTTONS.TABLE);
  const [metric, setMetric] = useState(METRICS.ATTRIBUTED_CLASSIC_ONLY);
  const [marketData, setMarketData] = useState();

  const [logs, setLogs] = useState([]);
  const access = useAccess();

  const {
    areMarketStatusesLoading, marketStatuses, reloadMarketStatuses,
  } = useMarketStatusesContext();

  const {
    isEquityBillingConfigurationsContextLoading, equityBillingConfigurationsContext,
  } = useEquityBillingConfigurationsContext();

  const [fetchMarketLogs, areLogsLoading] = useImperativeNetworkRequest(useCallback(() => getMarketEventLogsConfiguration({
    limit: 10,
  }), []));
  const [enableConfigurationVariable] = useImperativeNetworkRequest(() => enableConfigurationVariableConfiguration({
    market: selectedTriageMarket,
    configurationVariable: selectedTriageType,
  }));
  const [disableConfigurationVariable] = useImperativeNetworkRequest(() => disableConfigurationVariableConfiguration({
    market: selectedTriageMarket,
    configurationVariable: selectedTriageType,
  }));

  const getMarketLogs = useCallback(async () => {
    const result = await fetchMarketLogs();

    if (result?.isSuccess()) {
      setLogs(result.data.marketEventLogs);
    }
  }, [fetchMarketLogs]);

  function equityBillingEnabled(equityBillingConfiguration) {
    return equityBillingConfiguration.segments.length !== 0;
  }

  useEffect(() => { getMarketLogs(); }, [getMarketLogs]);
  useEffect(() => {
    if (marketStatuses && equityBillingConfigurationsContext?.equityBillingConfigurations?.some((configuration) => !!configuration.market)) {
      setMarketData(marketStatuses.map((ms) => ({
        ...ms,
        equityBillingEnabledMarket: equityBillingEnabled(equityBillingConfigurationsContext.equityBillingConfigurations.find((eb) => eb.market === ms.market)),
      })));
    }
  }, [marketStatuses, equityBillingConfigurationsContext]);

  function onMarketClick(marketAbbreviation) {
    history.push(`market/${marketAbbreviation}`);
  }

  function onTriageOptionClick(marketAbbreviation, marketTriageValue, marketTriageType) {
    setSelectedTriageMarket(marketAbbreviation);
    setSelectedTriageType(marketTriageType);
    setSelectedTriageValue(marketTriageValue);
    setIsTriageModalVisible(true);
  }

  function onTriageSubmit(value) {
    if (value === TRIAGE_YES) {
      enableConfigurationVariable().then(() => {
        return Promise.all([
          reloadMarketStatuses(),
          getMarketLogs(),
        ]);
      });
    } else {
      disableConfigurationVariable().then(() => Promise.all([
        reloadMarketStatuses(),
        getMarketLogs(),
      ]));
    }

    setIsTriageModalVisible(false);
  }

  return (
    <div>
      <section css={styles.navContainer}>
        <NavControls
          selection={viewType}
          setSelection={setViewType}
        />
      </section>
      {viewType === NAV_BUTTONS.TABLE &&
      <section css={styles.tableSection}>
        <p css={styles.logsTitle}>Root Markets</p>
        {areMarketStatusesLoading || isEquityBillingConfigurationsContextLoading || !marketData ?
          <div css={[styles.loader, styles.centerAligned]}>
            <CaretLoader />
          </div> :
          <MarketTable
            equityBillingData={equityBillingConfigurationsContext.equityBillingConfigurations}
            hasWriteAccess={access.hasWriteAccess()}
            marketData={marketData}
            onMarketClick={onMarketClick}
            onTriageOptionClick={onTriageOptionClick}
          />
        }
      </section>
      }
      {viewType === NAV_BUTTONS.MAP &&
      <section css={styles.mapSection}>
        <section css={styles.headerContainer}>
          <p css={styles.logsTitle}>Root Market Classifications</p>
          <section css={styles.metricControlsContainer}>
            <MetricControls
              metric={metric}
              setMetric={setMetric}
            />
          </section>
        </section>
        {areMarketStatusesLoading || isEquityBillingConfigurationsContextLoading ?
          <div css={[styles.loader, styles.centerAligned]}>
            <CaretLoader />
          </div> :
          <section>
            <section css={styles.map}>
              <MarketStatusMap
                marketStatuses={marketStatuses}
                metric={metric}
              />
            </section>
          </section>
        }
      </section>
      }
      <section css={styles.logsSection}>
        <p css={styles.logsTitle}>Market Updates</p>
        <p css={styles.logsDescription}>The ten most recent updates are listed below. To view older updates, check the #marketupdates channel in Slack.</p>
        <div css={styles.centerAligned}>
          {areLogsLoading ?
            <div css={styles.loader}>
              <CaretLoader />
            </div> :
            <MarketEventLogs
              logs={logs}
            />
          }
        </div>

      </section>
      {isTriageModalVisible && access.hasWriteAccess() &&
        <TriageOptionsModal
          market={selectedTriageMarket}
          onClose={() => setIsTriageModalVisible(false)}
          onSubmit={onTriageSubmit}
          triageType={selectedTriageType}
          triageValue={selectedTriageValue}
        />
      }
    </div>
  );
}

const styles = StyleSheet.create({
  tableSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    padding: '20px 40px',
  },
  mapSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    padding: '20px 50px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  map: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(0.90)',
    transformOrigin: 'top center',
  },
  metricControlsContainer: {
    justifyContent: 'left',
    width: '25%',
  },
  logsTitle: {
    ...Theme.heading1(),
  },
  logsDescription: {
    ...Theme.paragraph1(),
  },
  logsSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    padding: '50px',
  },
  centerAligned: {
    alignSelf: 'center',
  },
  loader: {
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
});
