import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import States from '@root/market-manager.admin.joinroot.com/src/models/states';
import Table from '@root/internal-dashboards-core/src/components/table';
import Triage from '@root/market-manager.admin.joinroot.com/src/models/triage';
import dayjs from '@root/vendor/dayjs';
import { StyleSheet } from '@root/core/src/utils/styles';

const columns = [{
  Header: 'Market',
  accessor: 'market',
  sortType: (row1, row2) => {
    const market1 = States.getMap()[row1.original.data.market];
    const market2 = States.getMap()[row2.original.data.market];

    return market1.localeCompare(market2);
  },
}, {
  Header: 'Attributed Classic-Only Market?',
  accessor: 'underperformingAttributed',
  sortType: (row1, row2) => {
    const value1 = row1.original.data.underperformingAttributedMarket ? 1 : 0;
    const value2 = row2.original.data.underperformingAttributedMarket ? 1 : 0;

    return value1 - value2;
  },
}, {
  Header: 'Unattributed Classic-Only Market?',
  accessor: 'underperformingUnattributed',
  sortType: (row1, row2) => {
    const value1 = row1.original.data.underperformingUnattributedMarket ? 1 : 0;
    const value2 = row2.original.data.underperformingUnattributedMarket ? 1 : 0;

    return value1 - value2;
  },
}, {
  Header: 'Reinstatement With Lapse Enabled Market?',
  accessor: 'lapseEnabled',
  sortType: (row1, row2) => {
    const value1 = row1.original.data.reinstatementWithLapseEnabledMarket ? 1 : 0;
    const value2 = row2.original.data.reinstatementWithLapseEnabledMarket ? 1 : 0;

    return value1 - value2;
  },
}, {
  Header: 'Fee Waivers Enabled Market?',
  accessor: 'feeWaiversEnabled',
  sortType: (row1, row2) => {
    const value1 = row1.original.data.feeWaiversEnabledMarket ? 1 : 0;
    const value2 = row2.original.data.feeWaiversEnabledMarket ? 1 : 0;

    return value1 - value2;
  },
}, {
  Header: 'Equity Billing Enabled?',
  accessor: 'equityBillingEnabled',
  sortType: (row1, row2) => {
    const value1 = row1.original.data.equityBillingEnabledMarket ? 1 : 0;
    const value2 = row2.original.data.equityBillingEnabledMarket ? 1 : 0;

    return value1 - value2;
  },
}, {
  Header: 'Last Triage Change',
  accessor: 'lastTriageChange',
  sortType: (row1, row2) => {
    const { updatedAt: time1, updatedBy: by1 } = row1.original.data;
    const { updatedAt: time2, updatedBy: by2 } = row2.original.data;

    const defined1 = !!(time1 && by1);
    const defined2 = !!(time2 && by2);

    if (!defined1 && !defined2) {
      return 0;
    }

    if (defined1 && !defined2) {
      return 1;
    }

    if (!defined1 && defined2) {
      return -1;
    }

    const timeDelta = Date.parse(time1) - Date.parse(time2);
    if (timeDelta !== 0) {
      return timeDelta;
    }

    return by1.localeCompare(by2);
  },
}];

export default function MarketTable({
  hasWriteAccess,
  marketData,
  onMarketClick,
  onTriageOptionClick,
}) {
  function triageOptionElement(market, triageValue, triageType) {
    const type = triageValue ? 'Yes' : 'No';

    return hasWriteAccess ?
      <div>
        <span>{`${type}`}</span><br />
        <a
          css={styles.cellLink}
          onClick={() => onTriageOptionClick(market, triageValue, triageType)}
        >
          (change)
        </a>
      </div> :
      <div>{type}</div>;
  }

  function equityBillingEnabledElement(equityBillingEnabledMarket, market) {
    const isEnabled = equityBillingEnabledMarket ? 'Yes' : 'No';

    return (
      <div>
        <span>{`${isEnabled}`}</span><br />
        <a
          css={styles.cellLink}
          onClick={(e) => { e.preventDefault(); onMarketClick(market); }}
        >
          (view)
        </a>
      </div>
    );
  }

  function createTableRow({
    market,
    underperformingAttributedMarket,
    underperformingUnattributedMarket,
    reinstatementWithLapseEnabledMarket,
    feeWaiversEnabledMarket,
    equityBillingEnabledMarket,
    updatedAt,
    updatedBy,
  }) {
    const marketCell = (
      <a
        css={styles.cellLink}
        onClick={(e) => { e.preventDefault(); onMarketClick(market); }}
      >
        {States.getMap()[market]}
      </a>
    );

    const attributedCell = triageOptionElement(
      market,
      underperformingAttributedMarket,
      Triage.TYPE.UNDERPERFORMING_ATTRIBUTED
    );

    const unattributedCell = triageOptionElement(
      market,
      underperformingUnattributedMarket,
      Triage.TYPE.UNDERPERFORMING_UNATTRIBUTED
    );

    const lapseCell = triageOptionElement(
      market,
      reinstatementWithLapseEnabledMarket,
      Triage.TYPE.REINSTATEMENT_WITH_LAPSE_ENABLED
    );

    const feeWaiverCell = triageOptionElement(
      market,
      feeWaiversEnabledMarket,
      Triage.TYPE.FEE_WAIVERS_ENABLED
    );

    const equityBillingCell = equityBillingEnabledElement(equityBillingEnabledMarket, market);

    const lastTriageChange = updatedAt && updatedBy
      ? `${dayjs(updatedAt).format('M/D/YYYY')} at ${dayjs(updatedAt).format('h:mm a')} by ${updatedBy}`
      : 'N/A';

    return {
      market: marketCell,
      underperformingAttributed: attributedCell,
      underperformingUnattributed: unattributedCell,
      lapseEnabled: lapseCell,
      feeWaiversEnabled: feeWaiverCell,
      equityBillingEnabled: equityBillingCell,
      lastTriageChange,
      data: {
        market,
        underperformingAttributedMarket,
        underperformingUnattributedMarket,
        reinstatementWithLapseEnabledMarket,
        feeWaiversEnabledMarket,
        equityBillingEnabledMarket,
        updatedAt,
        updatedBy,
      },
    };
  }

  return (
    <div css={styles.marketTable}>
      <Table
        columns={columns}
        data={marketData.map(createTableRow)}
        searchable={false}
      />
    </div>
  );
}

MarketTable.propTypes = {
  hasWriteAccess: PropTypes.bool.isRequired,
  marketData: PropTypes.arrayOf(PropTypes.shape({
    market: PropTypes.string.isRequired,
    underperformingAttributedMarket: PropTypes.bool.isRequired,
    underperformingUnattributedMarket: PropTypes.bool.isRequired,
    reinstatementWithLapseEnabledMarket: PropTypes.bool.isRequired,
    feeWaiversEnabledMarket: PropTypes.bool.isRequired,
    equityBillingEnabledMarket: PropTypes.bool.isRequired,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
  })).isRequired,
  onMarketClick: PropTypes.func.isRequired,
  onTriageOptionClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  marketTable: {
    width: '100%',
  },
  cellLink: {
    cursor: 'pointer',
  },
});
