import IconText from '@root/core/src/components/icon-text';
import InfoBanner, { Variants as BannerVariants } from '@root/core/src/components/info-banner';
import Input, { TextTypes } from '@root/core/src/components/input';
import PlusIcon from '@root/core/src/components/icons/plus-icon';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useMemo, useState } from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import setEquityBillingConfigurationsConfiguration from '@root/market-manager.admin.joinroot.com/src/api/set-equity-billing-configurations-configuration';
import trashIcon from '@root/core/src/assets/trash.svg';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { BUTTON_VARIANT_SECONDARY } from '@root/core/src/components/button/styles';
import { Button } from '@root/core/src/components/button/button';
import { Form, Formik } from 'formik';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function EquityBillingConfigurationCard({
  equityBillingConfiguration, selectablePlanVariants, market, hasWriteAccess,
}) {
  const [setEquityBillingSegments] = useImperativeNetworkRequest(setEquityBillingConfigurationsConfiguration);
  const planVariantOptions = selectablePlanVariants.map((v) => ({
    label: v.name,
    value: v.name,
    id: v.id,
  }));
  const [selectedSegments, setSelectedSegments] = useState([...equityBillingConfiguration.segments].sort((a, b) => b.minValue - a.minValue));

  const [validationError, setValidationError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [hasUnsavedData, setHasUnsavedData] = useState(false);

  function windowConfirm() {
    return window.confirm(
      'Are you sure you want to perform this function? Any unsaved data will be lost. Submit the existing data first before performing this action if you want to keep your current progress.'
    );
  }

  const handleSegmentAdd = () => {
    let userConfirmed = false;
    setSuccessMessage('');

    if (hasUnsavedData) {
      if (selectedSegments.length === 0) {
        userConfirmed = true;
      } else {
        userConfirmed = windowConfirm();
      }
    }

    if (!hasUnsavedData || userConfirmed) {
      setSelectedSegments([...selectedSegments, {
        maxValue: 100,
        minValue: 0,
        planVariant: {
          id: selectablePlanVariants[0]?.id,
          name: selectablePlanVariants[0]?.name,
        },
      }]);
    }
  };

  function areArraysOfObjectsEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    if (arr1.length === 0 && arr2.length === 0) {
      return true;
    }

    return arr1.every((item1) => {
      return arr2.some((item2) => {
        return JSON.stringify(item1) === JSON.stringify(item2);
      });
    });
  }

  useEffect(() => {
    const sortedInitialSegments = [...equityBillingConfiguration.segments].sort((a, b) => b.minValue - a.minValue);
    const sortedSelectedSegments = [...selectedSegments].sort((a, b) => b.minValue - a.minValue);

    if (areArraysOfObjectsEqual(sortedInitialSegments, sortedSelectedSegments) || successMessage) {
      setHasUnsavedData(false);
    } else {
      setHasUnsavedData(true);
    }
  }, [selectedSegments, equityBillingConfiguration.segments, successMessage]);

  const handleSegmentRemove = (index) => {
    let userConfirmed = false;
    setSuccessMessage('');
    if (hasUnsavedData) {
      userConfirmed = windowConfirm();
    }

    if (!hasUnsavedData || userConfirmed) {
      const modifiedSegments = [];
      selectedSegments.forEach((segment, i) => {
        if (i !== index) {
          modifiedSegments.push(segment);
        }
      });

      setSelectedSegments(modifiedSegments);
    }
  };

  const initialValues = useMemo(() => {
    return selectedSegments.map((segment) => ({
      minValue: segment.minValue,
      maxValue: segment.maxValue,
      planVariant: segment.planVariant.name,
    }));
  }, [selectedSegments]);

  const handleSubmit = async (segments) => {
    setValidationError('');
    setSuccessMessage('');

    let submittableFormat = segments[0] ? segments : [];

    if (segments[0]) {
      submittableFormat = segments.map((segment) => {
        return {
          minValue: segment.minValue,
          maxValue: segment.maxValue,
          equityBillingPlanVariantId: planVariantOptions.find((o) => o.value === segment.planVariant).id,
        };
      });
    }

    const updateSegmentsResult = await setEquityBillingSegments({
      segments: submittableFormat,
      market,
    });

    if (updateSegmentsResult.isSuccess()) {
      setSuccessMessage('Success!');
    } else {
      setValidationError(updateSegmentsResult.data.message);
    }
  };

  if (selectedSegments.length === 0 && hasUnsavedData) {
    return (
      <div css={styles.cardContainer}>
        <p css={styles.cardTitle}>Equity Billing Configuration</p>
        <div css={styles.notConfiguredText}>pressing submit will disable equity billing for this market</div>
        <Button
          autoReenable
          disabled={false}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          iconComponent={PlusIcon}
          iconPosition={'left'}
          onClick={handleSegmentAdd}
          variant={BUTTON_VARIANT_SECONDARY}
        >
          {'Add new segment'}
        </Button>
      </div>
    );
  }

  if (selectedSegments.length === 0) {
    return (
      <div css={styles.cardContainer}>
        <p css={styles.cardTitle}>Equity Billing Configuration</p>
        {successMessage &&
        <InfoBanner
          text={successMessage}
          variant={BannerVariants.SUCCESS}
        />
        }
        <div css={styles.notConfiguredText}>Equity billing has not been configured for this market.</div>
        <Button
          iconComponent={PlusIcon}
          iconPosition={'left'}
          onClick={handleSegmentAdd}
          variant={BUTTON_VARIANT_SECONDARY}
        >
          {'Add new segment'}
        </Button>
      </div>
    );
  }

  if (!hasWriteAccess) {
    return (
      <div css={styles.cardContainer}>
        <p css={styles.cardTitle}>Equity Billing Configuration</p>
        <table css={styles.table}>
          <thead>
            <tr>
              <th css={styles.thVariant}>Plan Variant</th>
              <th css={styles.thValues}>Min Value</th>
              <th css={styles.thValues}>Max Value</th>
            </tr>
          </thead>
          <tbody>
            {selectedSegments.map((item, index) => (
              <tr
                css={styles.tr}
                key={index}
              >
                <td css={styles.tdVariant}>{item.planVariant.name}</td>
                <td css={styles.tdValues}>{item.minValue}</td>
                <td css={styles.tdValues}>{item.maxValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div css={styles.cardContainer}>
      <p css={styles.cardTitle}>Equity Billing Configuration</p>
      {validationError &&
      <InfoBanner
        text={validationError}
        variant={BannerVariants.WARNING}
      />
      }
      {successMessage &&
        <InfoBanner
          text={successMessage}
          variant={BannerVariants.SUCCESS}
        />
      }
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={() => {}}
      >
        {({ values, setFieldValue }) => (

          <Form>
            <table css={styles.table}>
              <thead>
                <tr>
                  <th css={styles.thVariant}>Plan Variant</th>
                  <th css={styles.thValues}>Min Value</th>
                  <th css={styles.thValues}>Max Value</th>
                  <th css={styles.thValues} />
                </tr>
              </thead>
              <tbody>
                {values.map((segment, index) => {
                  return (
                    <tr
                      css={styles.tr}
                      key={index}
                    >
                      <td css={styles.tdVariant}>
                        <Select
                          inputId={`plan-variant-${index}`}
                          inputName={`values.${index}.planVariant`}
                          label={segment.planVariant}
                          onChange={(variant) => {
                            setHasUnsavedData(true);
                            setFieldValue(`${index}.planVariant`, variant);
                          }}
                          options={planVariantOptions}
                          placeholder={'Variant Name'}
                          selectedValue={segment.planVariant}
                        />
                      </td>
                      <td css={styles.tdValues}>
                        <Input
                          data-testid={`min-value-${index}`}
                          inputType={TextTypes.NUMBER}
                          key={`min-value-${index}`}
                          label={'Min Value'}
                          onChange={(num) => {
                            setHasUnsavedData(true);
                            setFieldValue(`${index}.minValue`, num);
                          }}
                          value={segment.minValue}
                        />
                      </td>
                      <td css={styles.tdValues}>
                        <Input
                          inputType={TextTypes.NUMBER}
                          key={`max-value-${index}`}
                          label={'Max Value'}
                          onChange={(num) => {
                            setHasUnsavedData(true);
                            setFieldValue(`${index}.maxValue`, num);
                          }}
                          value={segment.maxValue}
                        />
                      </td>
                      <td css={styles.tdValues}>
                        <IconText
                          icon={trashIcon}
                          iconAltText={'trash-icon'}
                          onClick={() => handleSegmentRemove(index)}
                          text={''}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Button
              autoReenable
              disabled={false}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <Button
        iconComponent={PlusIcon}
        iconPosition={'left'}
        onClick={handleSegmentAdd}
        variant={BUTTON_VARIANT_SECONDARY}
      >
        {'Add new segment'}
      </Button>
    </div>
  );
}

EquityBillingConfigurationCard.propTypes = {
  equityBillingConfiguration: PropTypes.shape({
    market: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(PropTypes.shape({
      minValue: PropTypes.number.isRequired,
      maxValue: PropTypes.number.isRequired,
      planVariant: PropTypes.object.isRequired,
    })).isRequired,
  }).isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
  market: PropTypes.string.isRequired,
  selectablePlanVariants: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
};

const styles = StyleSheet.create({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: 16,
  },
  thValues: {
    borderBottom: '2px solid #ddd',
    padding: 8,
    textAlign: 'center',
  },
  thVariant: {
    borderBottom: '2px solid #ddd',
    padding: 8,
    textAlign: 'left',
  },
  tdValues: {
    borderBottom: '1px solid #ddd',
    padding: 8,
    textAlign: 'center',
  },
  tdVariant: {
    borderBottom: '1px solid #ddd',
    padding: 8,
    textAlign: 'left',
  },
  tr: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f2f2',
    },
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: 25,
  },
  notConfiguredText: {
    marginBottom: 16,
  },
  cardContainer: {
    border: '1px solid',
    paddingTop: 5,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 6,
    minWidth: 450,
    maxWidth: 600,
    width: 'calc(50% - 10px)',
    boxSizing: 'border-box',
    marginTop: 5,
  },
});
