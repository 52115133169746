import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import { StyleSheet } from '@root/core/src/utils/styles';

const METRICS = {
  ATTRIBUTED_CLASSIC_ONLY: 'underperformingAttributedMarket',
  UNATTRIBUTED_CLASSIC_ONLY: 'underperformingUnattributedMarket',
  REINSTATEMENT_WITH_LAPSE_ENABLED: 'reinstatementWithLapseEnabledMarket',
  FEE_WAIVERS_ENABLED: 'feeWaiversEnabledMarket',
};

const METRICS_TO_TEXT = {
  [METRICS.ATTRIBUTED_CLASSIC_ONLY]: 'Attributed Classic-Only Markets',
  [METRICS.UNATTRIBUTED_CLASSIC_ONLY]: 'Unattributed Classic-Only Markets',
  [METRICS.REINSTATEMENT_WITH_LAPSE_ENABLED]: 'Reinstatement With Lapse Enabled Markets',
  [METRICS.FEE_WAIVERS_ENABLED]: 'Fee Waivers Enabled Markets',
};

export default function MetricControls({
  metric,
  setMetric,
}) {
  const metricOptions = Object.values(METRICS).map((metricType) => ({
    label: METRICS_TO_TEXT[metricType],
    value: metricType,
  }));

  return (
    <div data-testid={'configuration-variable-controls'}>
      <Select
        inputId={'configuration-variable-select'}
        inputName={'configuration-variable-select'}
        onChange={(e) => setMetric(e)}
        options={metricOptions}
        placeholder={'Configuration Variable'}
        selectBoxStyles={styles.selectBoxStyles}
        selectedValue={metric || ''}
      />
    </div>

  );
}

MetricControls.propTypes = {
  metric: PropTypes.string,
  setMetric: PropTypes.func.isRequired,
};

export { METRICS, METRICS_TO_TEXT };

const styles = StyleSheet.create({
  selectBoxStyles: {
    marginTop: 8,
  },
});
