import PropTypes from '@root/vendor/prop-types';
import { Colors } from '@root/core/src/utils/styles';

export default function PlusIcon({ strokeColor = Colors.rootOrange(), size = 17 }) {
  return (
    <svg
      fill={'none'}
      height={size}
      viewBox={'0 0 17 18'}
      width={size}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <line
        stroke={strokeColor}
        strokeWidth={'1.5'}
        x2={size}
        y1={'9.18677'}
        y2={'9.18677'}
      />
      <line
        stroke={strokeColor}
        strokeWidth={'1.5'}
        x1={'8.25'}
        x2={'8.25'}
        y1={size}
        y2={'0.936768'}
      />
    </svg>
  );
}

PlusIcon.propTypes = {
  size: PropTypes.number,
  strokeColor: PropTypes.string,
};
