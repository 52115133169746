import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Triage from '@root/market-manager.admin.joinroot.com/src/models/triage';
import dayjs from '@root/vendor/dayjs';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function MarketTriageStrategyCard({
  marketStatus,
  onTriageOptionClick,
  hasWriteAccess,
}) {
  const market = marketStatus.market;
  const lastTriageChange = marketStatus.updatedAt && marketStatus.updatedBy
    ? `${dayjs(marketStatus.updatedAt).format('M/D/YYYY')} at ${dayjs(marketStatus.updatedAt).format('h:mm a')} by ${marketStatus.updatedBy}`
    : 'N/A';

  return (
    <div css={styles.triageBox}>
      <p css={styles.triageTitle}>Triage Strategy</p>
      <p css={styles.triageSubtitle}>Attributed Classic-only market?</p>
      <div css={styles.triageValue}>
        <p css={styles.triageValueText}>{marketStatus.underperformingAttributedMarket ? 'Yes' : 'No'}</p>
        {hasWriteAccess &&
        <a
          css={styles.changeLink}
          onClick={() => onTriageOptionClick(
            market,
            marketStatus.underperformingAttributedMarket,
            Triage.TYPE.UNDERPERFORMING_ATTRIBUTED
          )}
        >(change)
        </a>
        }

      </div>
      <p css={styles.triageSubtitle}>Unattributed Classic-only market?</p>
      <div css={styles.triageValue}>
        <p css={styles.triageValueText}>{marketStatus.underperformingUnattributedMarket ? 'Yes' : 'No'}</p>
        {hasWriteAccess &&
        <a
          css={styles.changeLink}
          onClick={() => onTriageOptionClick(
            market,
            marketStatus.underperformingUnattributedMarket,
            Triage.TYPE.UNDERPERFORMING_UNATTRIBUTED
          )}
        >(change)
        </a>
        }
      </div>
      <p css={styles.triageSubtitle}>Reinstatement with lapse enabled market?</p>
      <div css={styles.triageValue}>
        <p css={styles.triageValueText}>{marketStatus.reinstatementWithLapseEnabledMarket ? 'Yes' : 'No'}</p>
        {hasWriteAccess &&
        <a
          css={styles.changeLink}
          onClick={() => onTriageOptionClick(
            market,
            marketStatus.reinstatementWithLapseEnabledMarket,
            Triage.TYPE.REINSTATEMENT_WITH_LAPSE_ENABLED
          )}
        >(change)
        </a>
        }
      </div>
      <p css={styles.triageSubtitle}>Fee waivers enabled market?</p>
      <div css={styles.triageValue}>
        <p css={styles.triageValueText}>{marketStatus.feeWaiversEnabledMarket ? 'Yes' : 'No'}</p>
        {hasWriteAccess &&
        <a
          css={styles.changeLink}
          onClick={() => onTriageOptionClick(
            market,
            marketStatus.feeWaiversEnabledMarket,
            Triage.TYPE.FEE_WAIVERS_ENABLED
          )}
        >(change)
        </a>
        }
      </div>
      <p css={styles.triageChangeTitle}>Last change: <span css={styles.triageChangeValue}>{lastTriageChange}</span></p>
    </div>
  );
}

MarketTriageStrategyCard.propTypes = {
  hasWriteAccess: PropTypes.bool.isRequired,
  marketStatus: PropTypes.shape({
    market: PropTypes.string.isRequired,
    underperformingAttributedMarket: PropTypes.bool.isRequired,
    underperformingUnattributedMarket: PropTypes.bool.isRequired,
    reinstatementWithLapseEnabledMarket: PropTypes.bool.isRequired,
    feeWaiversEnabledMarket: PropTypes.bool.isRequired,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
  }).isRequired,
  onTriageOptionClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  triageTitle: {
    fontWeight: 600,
    fontSize: 25,
  },
  triageValue: {
    fontSize: 22,
    display: 'flex',
  },
  triageSubtitle: {
    marginBottom: 0,
    color: Colors.gray60(),
    fontSize: 18,
  },
  triageChangeValue: {
    fontWeight: 'bold',
    color: Colors.black(),
  },
  triageChangeTitle: {
    color: Colors.gray60(),
    fontSize: 18,
  },
  triageValueText: {
    marginRight: 2,
  },
  triageBox: {
    border: '1px solid',
    paddingTop: 5,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 6,
    minWidth: 450,
    maxWidth: 600,
    width: 'calc(50% - 10px)',
    boxSizing: 'border-box',
    marginRight: 10,
    marginTop: 5,
  },
  changeLink: {
    cursor: 'pointer',
  },
});
