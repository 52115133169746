import Colors from '@root/brand/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';

export default function WashingtonDC({ styles, marketColor }) {
  return (
    <a
      className={marketColor}
      css={styles.marketLink}
      data-state-id={'DC'}
      data-testid={'DC'}
      href={'market/DC'}
    >
      <g
        className={'market'}
        css={styles.market}
      >
        <path
          d={'M801.8,253.8 l-1.1-1.6 -1-0.8 1.1-1.6 2.2,1.5z'}
        />
        <title>Washington D.C.</title>
        <circle
          cx={'801.3'}
          cy={'251.8'}

          opacity={'1'}
          r={'5'}
          stroke={`${Colors.white()}`}
          strokeWidth={'1.5'}
        />
      </g>
      <text
        css={[styles.marketLabel]}
        transform={'translate(858.73 256.6)'}
      >
        DC
      </text>
      <path
        css={styles.labelLine}
        d={'M854.5,250.5h-53'}
        transform={'translate(0, -0.4)'}
      />
    </a>
  );
}

WashingtonDC.propTypes = {
  marketColor: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
};
