import PropTypes from '@root/vendor/prop-types';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';

export const KEYWORDS = [
  'renters',
  'automotive',
  'auto',
  'homeowners',
  'enabled',
  'disabled',
  'classic',
  'day zero',
  'moratorium',
];

const KEYWORD_MATCHER = new RegExp(`\\b(${KEYWORDS.join('|')})`, 'gi');

export default function MarketEvent({
  description, market, whodunnit,
}) {
  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <p css={styles.market}>
          {market ?? ''}
        </p>
        <p css={styles.whoDunnIt}>
          {whodunnit && `Changed by: ${whodunnit}`}
        </p>
      </div>
      <div css={styles.body}>
        <HighlightKeywords
          description={description}
        />
      </div>
    </div>
  );
}

MarketEvent.propTypes = {
  description: PropTypes.string.isRequired,
  market: PropTypes.string,
  whodunnit: PropTypes.string,
};

function HighlightKeywords({ description }) {
  const highlighted = description
    .replace(KEYWORD_MATCHER, (word) => `<strong>${word}</strong>`);

  return (
    <p
      css={styles.description}
      dangerouslySetInnerHTML={{
        __html: highlighted,
      }}
    />
  );
}

HighlightKeywords.propTypes = {
  description: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  market: {
    ...Theme.heading3(),
    margin: '0px',
    color: Colors.white(),
  },
  whoDunnIt: {
    ...Theme.paragraph1(),
    margin: '0px',
    color: Colors.white(),
  },
  description: {
    ...Theme.paragraph1(),
    color: Colors.black(),
  },
  container: {
    ...Shadows.cardShadow(),
    width: 600,
    background: Colors.white(),
    minHeight: 144,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    border: '1px solid #D8D8D8',
  },
  header: {
    width: '100%',
    flexGrow: 0,
    height: 44,
    borderRadius: '6px 6px 0px 0px',
    padding: '12px',
    display: 'flex',
    background: Colors.rootOrange(),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    padding: '12px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

