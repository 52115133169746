import PropTypes from '@root/vendor/prop-types';
import noop from '@root/vendor/lodash/noop';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function IconText({
  containerStyles, disclaimerStyles, footerStyles, icon, iconAltText, iconStyles, onClick = noop, text,
}) {
  return (
    <div
      css={[styles.container, containerStyles]}
      onClick={onClick}
    >
      <div css={[styles.footer, footerStyles]}>
        <div css={[styles.iconAndDisclaimer, iconStyles]}>
          <img
            alt={iconAltText}
            src={icon}
          />
        </div>
        <div css={[styles.iconAndDisclaimer, disclaimerStyles]}>
          {text}
        </div>
      </div>
    </div>
  );
}

IconText.propTypes = {
  containerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  disclaimerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  footerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  icon: PropTypes.string.isRequired,
  iconAltText: PropTypes.string.isRequired,
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 40,
  },
  footer: {
    height: 36,
    display: 'flex',
    justifyContent: 'center',
  },
  iconAndDisclaimer: {
    ...Theme.caption(),
    color: Colors.gray50(),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
  },
});
