import PropTypes from '@root/vendor/prop-types';

const ArrowLeft = ({ strokeColor }) => (
  <svg
    data-testid={'left-arrow-icon'}
    fill={'none'}
    height={'13'}
    viewBox={'0 0 8 13'}
    width={'8'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M7.13672 11.9023L1.63672 6.40234L7.13672 0.902343'}
      stroke={strokeColor}
      strokeWidth={'2'}
    />
  </svg>
);

ArrowLeft.propTypes = {
  strokeColor: PropTypes.string,
};

export default ArrowLeft;
