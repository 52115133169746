import ArrowBack from '@root/market-manager.admin.joinroot.com/src/assests/arrow-back.svg';
import CaretLoader from '@root/core/src/components/caret-loader';
import EquityBillingConfigurationCard from '@root/market-manager.admin.joinroot.com/src/components/market-equity-billing-configuration-card';
import MarketEventLogs from '@root/market-manager.admin.joinroot.com/src/components/market-event-logs';
import MarketTriageStrategyCard from '@root/market-manager.admin.joinroot.com/src/components/market-triage-strategy-card';
import States from '@root/market-manager.admin.joinroot.com/src/models/states';
import TriageOptionsModal, { TRIAGE_YES } from '@root/market-manager.admin.joinroot.com/src/components/triage-options-modal';
import disableConfigurationVariableConfiguration from '@root/market-manager.admin.joinroot.com/src/api/disable-configuration-variable-configuration';
import enableConfigurationVariableConfiguration from '@root/market-manager.admin.joinroot.com/src/api/enable-configuration-variable-configuration';
import getMarketEventLogsConfiguration from '@root/market-manager.admin.joinroot.com/src/api/get-market-event-logs-configuration';
import propTypes from '@root/vendor/prop-types';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useAccess } from '@root/market-manager.admin.joinroot.com/src/services/access-service';
import { useCallback, useEffect, useState } from '@root/vendor/react';
import { useEquityBillingConfigurationsContext } from '@root/market-manager.admin.joinroot.com/src/hooks/use-equity-billing-configurations-context';
import { useHistory } from '@root/core/src/components/route';
import { useMarketStatusesContext } from '@root/market-manager.admin.joinroot.com/src/hooks/use-market-statuses-context';
export default function Market({ passProps }) {
  const history = useHistory();
  const access = useAccess();

  const marketShortName = passProps.match.params.market;
  const marketName = States.getMap()[marketShortName];

  const [selectedTriageType, setSelectedTriageType] = useState();
  const [selectedTriageValue, setSelectedTriageValue] = useState();
  const [isTriageModalVisible, setIsTriageModalVisible] = useState(false);
  const [logs, setLogs] = useState([]);
  const {
    areMarketStatusesLoading, marketStatuses, reloadMarketStatuses,
  } = useMarketStatusesContext();

  const {
    isEquityBillingConfigurationsContextLoading, equityBillingConfigurationsContext: { equityBillingConfigurations, planVariants },
  } = useEquityBillingConfigurationsContext();

  const [fetchMarketLogs, areLogsLoading] = useImperativeNetworkRequest(useCallback(() => getMarketEventLogsConfiguration({
    market: marketShortName,
  }), [marketShortName]));
  const [enableConfigurationVariable] = useImperativeNetworkRequest(useCallback(() => enableConfigurationVariableConfiguration({
    market: marketShortName,
    configurationVariable: selectedTriageType,
  }), [marketShortName, selectedTriageType]));
  const [disableConfigurationVariable] = useImperativeNetworkRequest(useCallback(() => disableConfigurationVariableConfiguration({
    market: marketShortName,
    configurationVariable: selectedTriageType,
  }), [marketShortName, selectedTriageType]));

  const getMarketLogs = useCallback(async () => {
    const result = await fetchMarketLogs();

    if (result?.isSuccess()) {
      setLogs(result.data.marketEventLogs);
    }
  }, [fetchMarketLogs]);

  useEffect(() => {
    getMarketLogs();
  }, [getMarketLogs]);

  function onBackClick() {
    history.push('/');
  }

  function onTriageOptionClick(marketAbbreviation, marketTriageValue, marketTriageType) {
    setSelectedTriageType(marketTriageType);
    setSelectedTriageValue(marketTriageValue);
    setIsTriageModalVisible(true);
  }

  function onTriageSubmit(value) {
    if (value === TRIAGE_YES) {
      enableConfigurationVariable().then(() => {
        return Promise.all([
          reloadMarketStatuses(),
          getMarketLogs(),
        ]);
      });
    } else {
      disableConfigurationVariable().then(() => Promise.all([
        reloadMarketStatuses(),
        getMarketLogs(),
      ]));
    }

    setIsTriageModalVisible(false);
  }

  const marketStatus = marketStatuses.find((ms) => ms.market === marketShortName);
  const equityBillingConfiguration = equityBillingConfigurations?.find((eb) => eb.market === marketShortName);
  return (
    <div>
      <a
        css={styles.link}
        onClick={() => onBackClick()}
      >
        <img
          css={styles.arrow}
          src={ArrowBack}
        />
        <p css={styles.subTitle}>Return</p>
      </a>
      <section css={styles.marketSection}>
        <p css={styles.marketTitle}>{marketName}</p>

        <section css={styles.marketCards}>
          {areMarketStatusesLoading ? (
            <div css={styles.loader}>
              <div css={[styles.loader, styles.centerAligned]}>
                <CaretLoader />
              </div>
            </div>
          ) :
            marketStatus ? (
              <MarketTriageStrategyCard
                hasWriteAccess={access.hasWriteAccess()}
                marketStatus={marketStatus}
                onTriageOptionClick={onTriageOptionClick}
              />
            ) :
              <p>{marketName} is not a supported market</p>
          }
          {isEquityBillingConfigurationsContextLoading ? (
            <div css={styles.loader}>
              <div css={[styles.loader, styles.centerAligned]}>
                <CaretLoader />
              </div>
            </div>
          ) :
            equityBillingConfiguration && (
              <EquityBillingConfigurationCard
                equityBillingConfiguration={equityBillingConfiguration}
                hasWriteAccess={access.hasWriteAccess()}
                market={marketShortName}
                selectablePlanVariants={planVariants}
              />
            )
          }
        </section>
        <p css={styles.marketUpdatesTitle}>{marketName} Market Updates</p>
        <div css={styles.marketEventLogs}>
          {areLogsLoading ?
            <div>
              <CaretLoader />
            </div> :
            <MarketEventLogs
              logs={logs.slice(0, 10)}
            />
          }
        </div>
      </section>
      {isTriageModalVisible &&
        <TriageOptionsModal
          market={marketShortName}
          onClose={() => setIsTriageModalVisible(false)}
          onSubmit={onTriageSubmit}
          triageType={selectedTriageType}
          triageValue={selectedTriageValue}
        />
      }
    </div>
  );
}

Market.propTypes = {
  passProps: propTypes.object.isRequired,
};

const styles = StyleSheet.create({
  marketSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    padding: '20px 48px',
  },
  marketCards: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '20px 48px',
  },
  centerAligned: {
    alignSelf: 'center',
  },
  marketTitle: {
    ...Theme.heading1(),
    marginBottom: 20,
  },
  marketUpdatesTitle: {
    ...Theme.heading1(),
    marginTop: 150,
  },
  marketEventLogs: {
    alignSelf: 'center',
  },
  loader: {
    height: 250,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minWidth: 450,
    maxWidth: 600,
    width: 'calc(50% - 10px)',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: 5,
    color: Colors.rootOrange(),
    cursor: 'pointer',
  },
  arrow: {
    marginRight: 5,
    marginBottom: 3,
  },
  subTitle: {
    fontSize: 18,
    marginBottom: 0,
    color: Colors.rootOrange(),
  },
});
