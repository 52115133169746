import EquityBillingConfigurationContextProvider from '@root/market-manager.admin.joinroot.com/src/hooks/use-equity-billing-configurations-context';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import Landing from '@root/market-manager.admin.joinroot.com/src/scenes/landing';
import Market from '@root/market-manager.admin.joinroot.com/src/scenes/market';
import MarketManagerWrapper from '@root/market-manager.admin.joinroot.com/src/components/market-manager-wrapper';
import MarketStatusesContextProvider from '@root/market-manager.admin.joinroot.com/src/hooks/use-market-statuses-context';
import OktaTokenAdapter from '@root/okta-auth/src/components/okta-token-adapter';
import PropTypes from '@root/vendor/prop-types';
import React, { Suspense } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import ScrollToTop from '@root/core/src/components/scroll-to-top';
import { LoginCallback, SecureRoute, Security } from '@root/vendor/@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@root/vendor/@okta/okta-auth-js';
import { OktaClaimsContextProvider } from '@root/okta-auth/src/hooks/use-okta-claims';
import { Route } from '@root/core/src/components/route';
import { useHistory } from '@root/vendor/react-router-dom';

const config = {
  issuer: `https://${ENV.OKTA_DOMAIN}/oauth2/${ENV.MARKET_MANAGER_OKTA_AUTH_SERVER_ID}`,
  redirectUri: `${ENV.MARKET_MANAGER_APP_BASE_URL}/implicit/callback`,
  clientId: ENV.MARKET_MANAGER_OKTA_CLIENT_ID,
  pkce: true,
};

const oktaAuth = new OktaAuth(config);

export const Routes = {
  LANDING: '/',
  MARKET: '/market/:market',
};

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, history.location.origin));
  };

  return (
    <ScrollToTop>
      <Suspense fallback={<SceneLoader />}>
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={restoreOriginalUri}
        >
          <OktaTokenAdapter />
          <OktaClaimsContextProvider>
            <MarketStatusesContextProvider>
              <EquityBillingConfigurationContextProvider>
                <SecureRoute
                  component={Landing}
                  exact={true}
                  passProps={{}}
                  path={Routes.LANDING}
                />
                <Route
                  component={LoginCallback}
                  path={'/implicit/callback'}
                />
                <SecureRoute
                  path={Routes.MARKET}
                  render={(passProps) => (
                    <Market
                      passProps={passProps}
                    />
                  )}
                />
              </EquityBillingConfigurationContextProvider>
            </MarketStatusesContextProvider>
          </OktaClaimsContextProvider>
        </Security>
      </Suspense>
    </ScrollToTop>
  );
}

export default function AppEntry({ routerComponent: Router }) {
  return (
    <ErrorBoundary>
      <MarketManagerWrapper>
        <Router>
          <App />
        </Router>
      </MarketManagerWrapper>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  routerComponent: PropTypes.func.isRequired,
};
