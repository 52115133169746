import Colors from '@root/brand/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import checkCircleFilledIcon from '@root/core/src/assets/checkmark-filled-green.svg';
import informationIcon from '@root/core/src/assets/information.svg';
import warningIcon from '@root/core/src/assets/warning.svg';
import { StyleSheet } from '@root/core/src/utils/styles';
import { Theme } from '@root/core/src/utils/styles';

export const Variants = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
};

const VARIANT_CONFIG = {
  [Variants.INFO]: {
    icon: {
      src: informationIcon,
      alt: 'Information icon',
    },
    container: {
      styles: {
        backgroundColor: Colors.blueTint(),
        borderColor: Colors.blue(),
      },
    },
  },
  [Variants.SUCCESS]: {
    icon: {
      src: checkCircleFilledIcon,
      alt: 'Check mark',
    },
    container: {
      styles: {
        backgroundColor: Colors.greenTint(),
        borderColor: Colors.green(),
      },
    },
  },
  [Variants.WARNING]: {
    icon: {
      src: warningIcon,
      alt: 'Warning symbol',
    },
    container: {
      styles: {
        backgroundColor: Colors.warnBackground(),
        borderColor: Colors.warn(),
      },
    },
  },
};

export default function InfoBanner({
  text,
  variant,
  className,
  cssOverrides = [],
}) {
  const variantConfig = VARIANT_CONFIG[variant];

  return (
    <div
      className={className}
      css={[styles.container, variantConfig.container.styles, ...cssOverrides]}
    >
      <img
        alt={variantConfig.icon.alt}
        css={styles.leftIcon}
        src={variantConfig.icon.src}
      />
      <div css={styles.message}>
        {text}
      </div>
    </div>
  );
}

const PADDING = 10;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexFlow: 'row',
    padding: PADDING,
    borderStyle: 'solid',
    borderRadius: 4,
    borderWidth: 1,
  },
  message: {
    ...Theme.heading5(),
  },
  leftIcon: {
    alignSelf: 'flex-start',
    paddingRight: PADDING,
  },
});

InfoBanner.propTypes = {
  className: PropTypes.string,
  cssOverrides: PropTypes.array,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([Variants.SUCCESS, Variants.WARNING, Variants.INFO]),
};

InfoBanner.Variants = Variants;
