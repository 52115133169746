import useOktaClaims from '@root/okta-auth/src/hooks/use-okta-claims';

export default class AccessService {
  static WRITE_ACCESS = 'triage_write_access';

  constructor(claims = {}) {
    this.claims = claims;
  }

  hasWriteAccess() {
    return this.claims[AccessService.WRITE_ACCESS] ?? false;
  }
}

export function useAccess() {
  const [claims] = useOktaClaims();
  return new AccessService(claims ?? {});
}
