import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import States from '@root/market-manager.admin.joinroot.com/src/models/states';
import Triage from '@root/market-manager.admin.joinroot.com/src/models/triage';
import XIcon from '@root/market-manager.admin.joinroot.com/src/components/icons/x-icon';
import alertFilled from '@root/core/src/assets/alert-filled-black.svg';
import capitalize from '@root/vendor/lodash/capitalize';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const TRIAGE_YES = 'y';
const TRIAGE_NO = 'n';

export default function TriageOptionsModal({
  market,
  onClose,
  onSubmit,
  triageType,
  triageValue,
}) {
  const [currentTriageValue, setCurrentTriageValue] = useState(triageValue ? TRIAGE_YES : TRIAGE_NO);

  function handleChange(evt) {
    setCurrentTriageValue(evt.target.value);
  }

  function handleSubmit() {
    onSubmit(currentTriageValue);
  }

  const marketFullName = States.getMap()[market];

  return (
    <div css={styles.bgOverlay}>
      <div css={styles.modal}>
        <a
          css={styles.xIcon}
          data-testid={'close-button'}
          onClick={(e) => { e.preventDefault(); onClose(); }}
        >
          <XIcon />
        </a>

        <div>
          <div css={styles.heading}>
            <h2>{capitalize(Triage.TYPE_TO_NAME[triageType])} Market Triage</h2>
          </div>
          <div css={styles.message}>
            Would you like to classify <b>{marketFullName}</b> as a <b>{Triage.TYPE_TO_TEXT[triageType]} market?</b>
          </div>
          <div css={styles.message}>
            Setting this to <b>Yes</b> will {Triage.TYPE_TO_MESSAGE[triageType]}
          </div>
          <div css={styles.button}>
            <RadioInputGroup
              selectedValue={currentTriageValue}
            >
              <RadioOption
                id={'triage-change-true'}
                isSelected={currentTriageValue === TRIAGE_YES}
                label={'Yes'}
                name={'triage-change-radio'}
                onChange={handleChange}
                optionValue={TRIAGE_YES}
              />
              <RadioOption
                id={'triage-change-false'}
                isSelected={currentTriageValue === TRIAGE_NO}
                label={'No'}
                name={'triage-change-radio'}
                onChange={handleChange}
                optionValue={TRIAGE_NO}
              />
            </RadioInputGroup>
          </div>

          <div css={styles.alertContent}>
            <div css={styles.alertIcon}>
              <img src={alertFilled} />
            </div>
            <div css={styles.headingText}>
              <b>Be aware that clicking on &quot;Save&quot; will update this state&apos;s triaging to what you selected above.</b>
            </div>
          </div>
          <div>
            <div >
              <Button
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  bgOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: Colors.toRgba(Colors.black(), 0.4),
  },
  modal: {
    position: 'relative',
    width: '100%',
    maxWidth: 400,
    background: Colors.white(),
    borderRadius: 6,
    boxShadow: `0 20px 60px -16px ${Colors.toRgba(Colors.black(), 0.27)}`,

    ...Responsive.md({
      padding: '35px 30px',
    }),
  },
  heading: {
    display: 'flex',
    flexDirection: 'horizontal',
    marginBottom: 10,
  },
  alertContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
    marginBottom: 10,
  },
  button: {
    marginTop: 30,
    marginBottom: 60,
  },
  alertIcon: {
    marginRight: 10,
  },
  message: {
    marginBottom: 20,
    fontSize: 18,
    lineHeight: '125%',
    color: Colors.black(),
  },
  xIcon: {
    position: 'absolute',
    top: 18,
    right: 18,

    svg: {
      display: 'block',
    },
  },
});

TriageOptionsModal.propTypes = {
  market: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  triageType: PropTypes.string.isRequired,
  triageValue: PropTypes.bool.isRequired,
};

export { TRIAGE_NO, TRIAGE_YES };
