import PropTypes from '@root/vendor/prop-types';
import React, { useContext, useEffect, useState } from '@root/vendor/react';
import { useOktaAuth } from '@root/vendor/@okta/okta-react';

const OktaClaimsContext = React.createContext(null);
const OktaClaimsSetContext = React.createContext(() => { });

export const OKTA_CLAIMS_STATUS = {
  Loading: 'loading',
  Authenticated: 'authenticated',
  UnAuthenticated: 'unauthenticated',
};

export function OktaClaimsContextProvider({ children }) {
  const [claims, setClaims] = useState(null);

  return (
    <OktaClaimsContext.Provider value={claims}>
      <OktaClaimsSetContext.Provider value={setClaims} >
        {children}
      </OktaClaimsSetContext.Provider>
    </OktaClaimsContext.Provider>
  );
}

OktaClaimsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useOktaClaims() {
  const [fetchingGetUser, setfetchingGetUser] = useState(false);
  const claims = useContext(OktaClaimsContext);
  const setClaims = useContext(OktaClaimsSetContext);
  const [status, setStatus] = useState(OKTA_CLAIMS_STATUS.Loading);
  const { authState, oktaAuth } = useOktaAuth();

  const isAuthenticated = authState?.isAuthenticated;
  const isLoading = !authState;

  useEffect(() => {
    if (isAuthenticated) {
      if (claims) { return; }

      if (!fetchingGetUser) {
        setfetchingGetUser(true);
        oktaAuth.getUser().then((info) => {
          setClaims(info);
          setStatus(OKTA_CLAIMS_STATUS.Authenticated);
        });
      }
    } else if (isLoading) {
      setClaims(null);
      setStatus(OKTA_CLAIMS_STATUS.Loading);
    } else {
      setClaims(null);
      setStatus(OKTA_CLAIMS_STATUS.UnAuthenticated);
    }
  }, [isAuthenticated, isLoading, oktaAuth, claims, fetchingGetUser, setClaims, status]);

  return [claims, status];
}
