import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useContext, useEffect, useState } from '@root/vendor/react';
import getEquityBillingConfigurationsContextConfiguration from '@root/market-manager.admin.joinroot.com/src/api/get-equity-billing-configurations-context-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useOktaAuth } from '@root/vendor/@okta/okta-react';

const EquityBillingConfigurationsContext = React.createContext();

export default function EquityBillingConfigurationContextProvider({ children }) {
  const { authState } = useOktaAuth();

  const isAuthenticated = authState?.isAuthenticated;

  const [fetchEquityBillingConfigurationsContext, isEquityBillingConfigurationsContextLoading] = useImperativeNetworkRequest(getEquityBillingConfigurationsContextConfiguration);

  const [equityBillingConfigurationsContext, setEquityBillingConfigurationsContext] = useState([]);

  const getEquityBillingConfigurationsContext = useCallback(async () => {
    if (isAuthenticated) {
      const result = await fetchEquityBillingConfigurationsContext();

      if (result?.isSuccess()) {
        setEquityBillingConfigurationsContext(result.data);
      }
    }
  }, [fetchEquityBillingConfigurationsContext, isAuthenticated]);

  useEffect(() => {
    if (equityBillingConfigurationsContext.length === 0) {
      getEquityBillingConfigurationsContext();
    }
  }, [getEquityBillingConfigurationsContext, equityBillingConfigurationsContext]);

  return (
    <EquityBillingConfigurationsContext.Provider
      value={{
        isEquityBillingConfigurationsContextLoading,
        equityBillingConfigurationsContext,
        reloadEquityBillingConfigurationsContext: getEquityBillingConfigurationsContext,
      }}
    >
      {children}
    </EquityBillingConfigurationsContext.Provider>
  );
}

EquityBillingConfigurationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useEquityBillingConfigurationsContext() {
  const {
    isEquityBillingConfigurationsContextLoading, equityBillingConfigurationsContext, reloadEquityBillingConfigurationsContext,
  } = useContext(EquityBillingConfigurationsContext);

  return {
    isEquityBillingConfigurationsContextLoading,
    equityBillingConfigurationsContext,
    reloadEquityBillingConfigurationsContext,
  };
}
