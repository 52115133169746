import Colors from '@root/brand/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { METRICS_TO_TEXT } from '@root/market-manager.admin.joinroot.com/src/components/map-section/metric-controls';

import { StyleSheet } from '@root/core/src/utils/styles';

const legendText = (metric) => {
  return [`${METRICS_TO_TEXT[metric]}`, 'Other Root markets'];
};

export default function MapLegend({ metric }) {
  return (
    <svg>
      <rect
        fill={Colors.rootOrange()}
        height={'20'}
        width={'20'}
        x={'830'}
        y={'400'}
      />
      <text
        css={styles.legendLabel}
        transform={'translate(855, 413)'}
      > {legendText(metric)[0]}
      </text>

      <rect
        fill={'#FFD5C4'}
        height={'20'}
        width={'20'}
        x={'830'}
        y={'440'}
      />
      <text
        css={styles.legendLabel}
        transform={'translate(855, 453)'}
      > {legendText(metric)[1]}
      </text>

      <rect
        fill={'#EDEDED'}
        height={'20'}
        width={'20'}
        x={'830'}
        y={'480'}
      />
      <text
        css={styles.legendLabel}
        transform={'translate(855, 493)'}
      > Non-Root markets
      </text>

    </svg>
  );
}

MapLegend.propTypes = {
  metric: PropTypes.string.isRequired,
};

export { legendText };

const styles = StyleSheet.create({
  legendLabel: {
    fill: '#000000',
    fontSize: 12,
  },
});
