import AuthService from '@root/core/src/services/auth-service';
import { useEffect } from '@root/vendor/react';
import { useOktaAuth } from '@root/vendor/@okta/okta-react';

function OktaTokenAdapter() {
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (authState?.accessToken) {
      AuthService.setAccessToken(`Bearer ${authState.accessToken.accessToken}`);
    } else if (!authState) {
      AuthService.clear();
    }
  }, [authState]);

  return null;
}

export default OktaTokenAdapter;
