import PropTypes from '@root/vendor/prop-types';
import Root from '@root/market-manager.admin.joinroot.com/src/assests/root.svg';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function MarketManagerWrapper({ children }) {
  return (
    <div css={styles.container}>
      <div css={styles.header} >
        <a
          aria-label={'Root Market Manager logo'}
          css={styles.link}
          href={'/'}
        >
          <img
            css={styles.title}
            src={Root}
          />
          <p css={styles.subTitle}>Market Manager</p>
        </a>
      </div>
      <div css={styles.body}>
        {children}
      </div>
    </div>
  );
}

MarketManagerWrapper.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Colors.white(),
  },
  header: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: 66,
    backgroundColor: Colors.white(),
    display: 'flex',
    alignItems: 'center',
    borderBottom: '2px solid',
    borderColor: Colors.gray30(),
    padding: '0px 20px',
  },
  body: {
    marginTop: 66,
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  title: {
    height: 18,
    marginRight: 5,
    marginBottom: 4,
  },
  subTitle: {
    ...Theme.paragraph1(),
    marginBottom: 0,
    color: Colors.black(),
  },
});
