import Colors from '@root/brand/src/utils/colors';
import MapData from '@root/market-manager.admin.joinroot.com/test/support/data/markets.js';
import MapLegend from '@root/market-manager.admin.joinroot.com/src/components/map-section/map-legend';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import WashingtonDC from '@root/market-manager.admin.joinroot.com/src/components/map-section/washington-dc';
import { StyleSheet } from '@root/core/src/utils/styles';

const MARKET_COLOR = {
  ENABLED: 'market-dark-orange',
  DISABLED: 'market-light-orange',
  NOT_AVAILABLE: 'market-grey',
};

const mapColor = (marketStatuses, marketName, metric) => {
  const market = marketStatuses.find((marketStatus) => marketStatus.market === marketName);
  if (market) {
    return market[metric] ? MARKET_COLOR.ENABLED : MARKET_COLOR.DISABLED;
  }

  return MARKET_COLOR.NOT_AVAILABLE;
};

export default function MarketStatusMap({ metric, marketStatuses }) {
  return (
    <svg
      css={styles.marketStatusMap}
      data-testid={'market-status-map'}
      viewBox={'0 0 1025 593'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <g css={styles.markets}>
        {MapData.map((mapData, index) => {
          return (
            <a
              className={mapColor(marketStatuses, mapData.id, metric)}
              css={styles.marketLink}
              data-state-id={mapData.id}
              data-testid={mapData.id}
              href={marketStatuses.find((marketStatus) => marketStatus.market === mapData.id) ? `market/${mapData.id}` : null}
              key={index}
            >
              <path
                className={'market'}
                css={styles.market}
                d={mapData.shape.dimensions}
              />
              <title>{mapData.name}</title>
              {mapData.shape.labelLine &&
                <path
                  css={styles.labelLine}
                  d={mapData.shape.labelLine.dimensions}
                  transform={'translate(0, -0.4)'}
                />
              }
              <text
                css={mapData.shape.labelLine ? styles.marketLabelWithLine : styles.marketLabel}
                transform={`${mapData.shape.labelTransform}`}
              >
                {mapData.id.toUpperCase()}
              </text>
            </a>
          );
        })}

        <WashingtonDC
          marketColor={mapColor(marketStatuses, 'DC', metric)}
          styles={styles}
        />
      </g>
      <MapLegend
        metric={metric}
      />
    </svg>
  );
}

MarketStatusMap.propTypes = {
  marketStatuses: PropTypes.arrayOf(PropTypes.shape({
    market: PropTypes.string,
  })).isRequired,
  metric: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  marketStatusMap: {
    width: '100%',
    maxWidth: 1300,
    height: 'auto',
  },
  marketLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  market: {
    fill: '#EDEDED',
    transition: 'fill 300ms ease-in-out',
    '.market-dark-orange &': {
      fill: Colors.rootOrange(),
    },
    '.market-dark-orange:hover &': {
      fill: '#c83800',
    },
    '.market-light-orange &': {
      fill: '#FFD5C4',
    },
    '.market-light-orange:hover &': {
      fill: '#ce785e',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  marketLabel: {
    fill: Colors.black(),
    fontSize: 20,
    '@media screen and (min-width: 768px)': {
      fontSize: 15,
    },
    '.market-dark-orange &': {
      fill: Colors.white(),
    },
  },
  labelLine: {
    stroke: Colors.gray50(),
    strokeWidth: 1,
  },
  marketLabelWithLine: {
    transition: 'fill 300ms ease-in-out',
    fill: Colors.black(),
  },
});
