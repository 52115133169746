import MarketManagerAdminApiNetworkRequestConfiguration from '@root/market-manager.admin.joinroot.com/src/api/market-manager-admin-api-network-request-configuration';

export default function enableConfigurationVariableConfiguration({ configurationVariable, market }) {
  return new MarketManagerAdminApiNetworkRequestConfiguration({
    endpoint: 'configuration_variable/enable',
    bodyParameters: {
      configurationVariable,
      market,
    },
    successCodes: [200, 204],
    method: MarketManagerAdminApiNetworkRequestConfiguration.Methods.POST,
  });
}
